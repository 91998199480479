(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/helpers/get-next-draw-close-time.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/helpers/get-next-draw-close-time.js');
'use strict';

const {
  BUY_STOP_DIFF
} = svs.components.marketplaceData.createGame.constants;
const getNextDrawCloseTime = function (productDraws) {
  let buyStop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BUY_STOP_DIFF;
  const closeTimeOffsetMs = buyStop * 60 * 1000;
  const now = new Date();
  const allRegCloseTimes = (productDraws || []).filter(draw => draw.drawState === 'Open').map(draw => new Date(draw.regCloseTime.getTime() - closeTimeOffsetMs)).filter(dateTime => dateTime > now);
  const nextCloseTimeOffsetMs = Math.min(...allRegCloseTimes);
  return nextCloseTimeOffsetMs ? new Date(nextCloseTimeOffsetMs) : undefined;
};
setGlobal('svs.components.marketplaceData.createGame.helpers.getNextDrawCloseTime', getNextDrawCloseTime);

 })(window);