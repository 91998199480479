(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/helpers/get-product-items.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/helpers/get-product-items.js');
"use strict";


const {
  BUY_STOP_DIFF,
  DISABLED_REASONS
} = svs.components.marketplaceData.createGame.constants;
const {
  NO_DRAW,
  MINIMUM_DIFF_TO_DRAW_PASSED
} = DISABLED_REASONS;
const {
  getJackpots
} = svs.content.shared;
const {
  getFunds
} = svs.components.lbUtils.funds;
const {
  TTLCacheAsync
} = svs.components.lbUtils.ttlCache;
const CACHE_ID = 'fundsAndJackpotsCache';
const CACHE_TIME = 120000000; 
const CACHE_TIMEOUT = 3000; 

const _queryJackpotAndFunds = async _ref => {
  let {
    payload
  } = _ref;
  const {
    allProductNames
  } = payload;
  const [jackpots, funds] = await Promise.all([getJackpots(allProductNames), getFunds({
    products: allProductNames
  })]);
  return {
    jackpots,
    funds
  };
};
const Global = Object.seal({
  jackpotAndFundsCache: new TTLCacheAsync({
    onRefreshAsync: _queryJackpotAndFunds,
    ttl: CACHE_TIME,
    timeoutMS: CACHE_TIMEOUT
  })
});
const getProductItems = async _ref2 => {
  let {
    allProductNames = [],
    drawsByProduct = [],
    buyStop = BUY_STOP_DIFF
  } = _ref2;
  const minimumBufferInMs = buyStop * 60 * 1000;
  const now = Date.now();
  const latestClosingTime = new Date(now + minimumBufferInMs);
  const cachedData = (await Global.jackpotAndFundsCache.readAsync({
    key: CACHE_ID,
    payload: {
      allProductNames
    }
  })) || {};
  const jackpots = (cachedData === null || cachedData === void 0 ? void 0 : cachedData.jackpots) || [];
  const funds = (cachedData === null || cachedData === void 0 ? void 0 : cachedData.funds) || [];
  const getJackpotsByProductName = productName => jackpots.filter(jackpot => jackpot.productName.toLowerCase() === productName.toLowerCase());
  const getFundsByProductName = productName => funds.filter(fund => fund.productName.toLowerCase() === productName.toLowerCase());
  const productItems = await Promise.all(allProductNames.map(async item => {
    const drawsForProduct = drawsByProduct[item];
    if ((drawsForProduct || []).length === 0) {
      return {
        item,
        disabledReason: NO_DRAW,
        isDisabled: true
      };
    }
    const disabledReason = drawsForProduct.some(draw => new Date(draw.regCloseTime) > latestClosingTime) ? undefined : MINIMUM_DIFF_TO_DRAW_PASSED;
    return {
      item,
      disabledReason,
      isDisabled: Boolean(disabledReason),
      jackpots: getJackpotsByProductName(item),
      funds: getFundsByProductName(item)
    };
  }));
  return productItems;
};
setGlobal('svs.components.marketplaceData.createGame.helpers.getProductItems', getProductItems);

 })(window);