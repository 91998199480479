(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/helpers/get-game-name.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/helpers/get-game-name.js');
"use strict";


const {
  helpers,
  getDisplayName
} = svs.utils.products;
const {
  SubProductId
} = svs.components.sport.tipsenShared;
const getGameName = draw => {
  if (!draw) {
    return '';
  }
  if (helpers.isChallenge(draw.productId)) {
    const normalisedName = draw.name.replace(/_/g, ' ').replace(/-/g, ' ').replace(/ {2}/g, ' ');
    return normalisedName.length >= 30 ? normalisedName.slice(0, 30) : normalisedName;
  }
  if (helpers.isBomben(draw.productId) || helpers.isChallenge(draw.productId) || helpers.isRacing(draw.productId) || draw.subProductId === SubProductId.EM_TIPSET || draw.subProductId === SubProductId.VM_TIPSET) {
    return draw.name.replace(/_/g, ' ').replace(/ {2}/g, ' ');
  }
  return "".concat(getDisplayName(draw.productId), " ").concat(draw.name).toLowerCase();
};
setGlobal('svs.components.marketplaceData.createGame.helpers.getGameName', getGameName);

 })(window);