(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/services/fetch-account-balance.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/services/fetch-account-balance.js');
"use strict";


const {
  jupiter
} = svs.core;
setGlobal('svs.components.marketplaceData.createGame.services.fetchAccountBalance', callback => {
  jupiter.get('/player/1/info', _ref => {
    let {
      accountBalance
    } = _ref;
    const balance = Number(accountBalance.replace(',', '.'));
    callback(null, balance);
  }, error => {
    callback(error.responseJSON.error);
  });
});

 })(window);