(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/create-game-constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/create-game-constants.js');
"use strict";

setGlobal('svs.components.marketplaceData.createGame.constants', {
  MAX_FRACTIONS_PER_MEMBER: 25,
  MAX_FRACTIONS_PER_GAME: 50,
  MIN_FRACTIONS_PER_GAME: 2,
  BUY_STOP_DIFF: 5,
  DISABLED_REASONS: {
    NO_DRAW: 'INGEN OMGÅNG',
    MINIMUM_DIFF_TO_DRAW_PASSED: '< 5 MIN'
  }
});

 })(window);