(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/helpers/get-draw-items.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/helpers/get-draw-items.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  BUY_STOP_DIFF,
  DISABLED_REASONS
} = svs.components.marketplaceData.createGame.constants;
const {
  MINIMUM_DIFF_TO_DRAW_PASSED
} = DISABLED_REASONS;
const minimumBufferInMs = BUY_STOP_DIFF * 60 * 1000;
const getDrawItems = productDraws => (productDraws || []).map(_ref => {
  let {
    name,
    regCloseTime,
    productId,
    drawNumber
  } = _ref;
  const latestClosingTime = new Date(Date.now() + minimumBufferInMs);
  const isDisabled = new Date(regCloseTime) < latestClosingTime;
  return _objectSpread({
    item: {
      name,
      regCloseTime,
      drawNumber,
      key: "".concat(productId, "_").concat(drawNumber)
    },
    isDisabled
  }, isDisabled ? {
    disabledReason: MINIMUM_DIFF_TO_DRAW_PASSED
  } : {});
});
setGlobal('svs.components.marketplaceData.createGame.helpers.getDrawItems', getDrawItems);

 })(window);