(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/services/create-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/services/create-game.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  resolveTimeZone
} = svs.components.lbUtils.helpers.formatDate;
const createGame = (groupId, game, callback) => {
  const containerPayload = {
    name: game.name,
    productId: game.productId,
    drawNumber: game.drawNumber,
    maxSharesPerMember: game.maxFractionsPerMember,
    maxNumShares: game.maxFractionsPerGame,
    sharePrice: svs.utils.format.Currency(game.fractionPrice, true).replace(/\s/g, ''),
    buyinCloseTime: dateFns.formatISO(resolveTimeZone(game.buyStop)),
    drawDescription: game.description,
    description: game.description
  };
  jupiter.post({
    path: "/marketplace/1/groups/".concat(groupId, "/activateddraws"),
    data: containerPayload
  }, res => {
    callback(undefined, res.CreateActivatedDrawReply);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.createGame.services.createGame', createGame);

 })(window);