(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/create-game-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/create-game-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createBetProposal,
  updateBetProposal
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  draws,
  createGame: {
    helpers,
    services
  },
  games: {
    services: gameServices
  },
  selectors: {
    games: gamesSelectors
  },
  dialogWrapper,
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  constants: errorCodeHandlerWarningConstants
} = errorCodeHandler;
const FETCH_GAME = 'CREATE_GAME/FETCH_GAME';
const FETCH_GAME_FAILED = 'CREATE_GAME/FETCH_GAME_FAILED';
const FETCH_GAME_SUCCESS = 'CREATE_GAME/FETCH_GAME_SUCCESS';
const fetchGame = (groupId, containerId) => (dispatch, getState) => {
  dispatch({
    type: FETCH_GAME
  });
  gameServices.fetchContainer(groupId, containerId, (error, game) => {
    if (error) {
      dispatch({
        type: FETCH_GAME_FAILED,
        errorMessage: error.message,
        containerId
      });
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelet kunde inte visas.',
        type: 'error'
      }));
    } else {
      const {
        Members
      } = getState();
      const {
        id: memberId
      } = Members[game.groupId].find(member => member.isMe);
      dispatch(draws.actions.fetchSingleDrawAction(game.productId, game.drawNumber));
      dispatch({
        type: FETCH_GAME_SUCCESS,
        game,
        memberId,
        containerId,
        groupId
      });
    }
  });
};
const SELECT_PRODUCT = 'CREATE_GAME/SELECT_PRODUCT';
const selectProduct = productName => ({
  type: SELECT_PRODUCT,
  productName
});
const SELECT_DRAW = 'CREATE_GAME/SELECT_DRAW';
const selectDraw = drawNumber => (dispatch, getState) => {
  const {
    Draws,
    CreateGame
  } = getState();
  const productDraws = Draws.draws[CreateGame.selectedProduct];
  const draw = productDraws.find(draw => draw.drawNumber === drawNumber);
  dispatch({
    type: SELECT_DRAW,
    drawNumber,
    name: helpers.getGameName(draw)
  });
};
const SELECTED_DRAW_CLOSED = 'CREATE_GAME/SELECTED_DRAW_CLOSED';
const selectedDrawClosed = drawNumber => ({
  type: SELECTED_DRAW_CLOSED,
  drawNumber
});
const CHANGE_BUY_STOP_DIFF = 'CREATE_GAME/CHANGE_BUY_STOP_DIFF';
const changeBuyStopDiff = buyStopDiff => ({
  type: CHANGE_BUY_STOP_DIFF,
  buyStopDiff
});
const CHANGE_FRACTION_PRICE = 'CREATE_GAME/CHANGE_FRACTION_PRICE';
const changeFractionPrice = fractionPrice => ({
  type: CHANGE_FRACTION_PRICE,
  fractionPrice
});
const CHANGE_DESCRIPTION = 'CREATE_GAME/CHANGE_DESCRIPTION';
const changeDescription = description => ({
  type: CHANGE_DESCRIPTION,
  description
});
const CHANGE_MAX_FRACTIONS_PER_MEMBER = 'CREATE_GAME/CHANGE_MAX_FRACTIONS_PER_MEMBER';
const changeMaxFractionsPerMember = maxFractionsPerMember => ({
  type: CHANGE_MAX_FRACTIONS_PER_MEMBER,
  maxFractionsPerMember
});
const CHANGE_MAX_FRACTIONS_PER_GAME = 'CREATE_GAME/CHANGE_MAX_FRACTIONS_PER_GAME';
const changeMaxFractionsPerGame = maxFractionsPerGame => ({
  type: CHANGE_MAX_FRACTIONS_PER_GAME,
  maxFractionsPerGame
});
const {
  getRowId: getBetProposalRowId
} = svs.components.marketplace.helpers;
const CREATE_GAME = 'CREATE_GAME/CREATE_GAME';
const CREATE_GAME_SUCCESS = 'CREATE_GAME/CREATE_GAME_SUCCESS';
const CREATE_GAME_FAILED = 'CREATE_GAME/CREATE_GAME_FAILED';
const createGame = function (groupId) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => null;
  return (dispatch, getState) => {
    const state = getState();
    const {
      Draws,
      CreateGame,
      Members
    } = state;
    const {
      id: memberId
    } = Members[groupId].find(member => member.isMe);
    const newGame = _objectSpread(_objectSpread({}, helpers.mapStateToGame(groupId, CreateGame, Draws)), {}, {
      fractions: {
        [memberId]: 1
      }
    });
    dispatch({
      type: CREATE_GAME,
      groupId,
      productName: newGame.productName,
      drawNumber: newGame.drawNumber
    });
    services.createGame(groupId, newGame, (error, game) => {
      if (error) {
        errorCodeHandler.getErrorMessage(error.code, err => {
          dispatch({
            type: CREATE_GAME_FAILED,
            groupId,
            productName: newGame.productName,
            drawNumber: newGame.drawNumber,
            error
          });
          if (err.warning === errorCodeHandlerWarningConstants.warnings.PLAYER_REACHED_TIMELIMIT) {
            const {
              title,
              text,
              buttons,
              icon
            } = svs.components.marketplaceShared.specificDialogs.dialogs.timelimitDialog;
            dispatch(dialogWrapper.actions.showDialog({
              title,
              text,
              buttons,
              icon
            }));
          } else {
            dispatch(dialogWrapper.actions.showDialog({
              title: err.message,
              type: 'error'
            }));
          }
          callback(error);
        }, {
          productId: newGame.productId,
          shouldCheckPlayerExclusions: true,
          specificGameMessage: true
        });
      } else {
        const rowId = getBetProposalRowId(newGame.productName, newGame.drawNumber, groupId);
        const hasBetProposal = Object.keys((getState().CreateBetProposal[rowId] || {}).data || {}).length >= 1;
        createBetProposal(newGame.productName, newGame.drawNumber, groupId, game.activatedDrawId, null)(dispatch, getState);
        dispatch({
          type: CREATE_GAME_SUCCESS,
          game: _objectSpread(_objectSpread({}, newGame), {}, {
            activatedDrawId: game.activatedDrawId,
            containerId: game.containerId
          }),
          groupId,
          productName: newGame.productName,
          drawNumber: newGame.drawNumber,
          hasBetProposal
        });
        const buyStop = newGame.buyStop;
        dispatch(dialogWrapper.actions.showDialog({
          title: "Klart! Dina lagkompisar kan k\xF6pa andelar fram till ".concat(buyStop.getDate(), "/").concat(buyStop.getMonth() + 1, " kl ").concat(buyStop.toTimeString().slice(0, 5)),
          icon: 'checkmark'
        }));
        callback(null, game);
      }
    });
  };
};
const UPDATE_GAME = 'CREATE_GAME/UPDATE_GAME';
const UPDATE_GAME_FAILED = 'CREATE_GAME/UPDATE_GAME_FAILED';
const UPDATE_GAME_SUCCESS = 'CREATE_GAME/UPDATE_GAME_SUCCESS';
const updateGame = function (groupId, activatedDrawId) {
  let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => null;
  return (dispatch, getState) => {
    const {
      warnings
    } = errorCodeHandler.constants;
    const {
      CreateGame,
      Draws
    } = getState();
    const currentGame = gamesSelectors.getGame(getState(), {
      activatedDrawId,
      groupId
    });
    const updatedGame = _objectSpread(_objectSpread({}, helpers.mapStateToGame(groupId, CreateGame, Draws)), {}, {
      activatedDrawId
    });
    const updatedGameData = _objectSpread(_objectSpread(_objectSpread({}, updatedGame.maxFractionsPerMember > currentGame.maxFractionsPerMember ? {
      maxFractionsPerMember: updatedGame.maxFractionsPerMember
    } : {}), updatedGame.description !== currentGame.description ? {
      description: updatedGame.description
    } : {}), new Date(updatedGame.buyStop).getTime() !== new Date(currentGame.buyStop).getTime() ? {
      buyStop: new Date(updatedGame.buyStop)
    } : {});
    const hadFormChanges = Object.keys(updatedGameData).length > 0;
    dispatch(updateBetProposal(CreateGame.activatedDrawId, groupId, {
      alertChanges: !hadFormChanges,
      product: CreateGame.selectedProduct
    }));
    if (!hadFormChanges) {
      return callback();
    }
    dispatch({
      type: UPDATE_GAME,
      groupId,
      activatedDrawId
    });
    services.updateGame(_objectSpread(_objectSpread({}, updatedGameData), {}, {
      activatedDrawId
    }), (error, game) => {
      if (error) {
        svs.components.marketplaceData.errorCodeHandler.getErrorMessage(error.code, err => {
          dispatch({
            type: UPDATE_GAME_FAILED,
            error,
            groupId,
            activatedDrawId
          });
          dispatch(dialogWrapper.actions.showDialog({
            title: err.message,
            type: 'error'
          }));
          callback(error, null);
        }, {
          overrideErrorMessage: {
            [warnings.INVALID_PARAM]: 'Det ser ut som att felaktig information angavs, se över om något har ändrats och försök igen.'
          }
        });
      } else {
        dispatch({
          type: UPDATE_GAME_SUCCESS,
          game: _objectSpread(_objectSpread({}, game), {}, {
            groupId
          })
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Ändringarna har sparats.',
          icon: 'checkmark'
        }));
        callback(null, game);
      }
    });
  };
};
const CLEAR_FORM = 'CREATE_GAME/CLEAR_FORM';
const clearFormAction = () => ({
  type: CLEAR_FORM
});
const RESET_VALUES = 'CREATE_GAME/RESET_VALUES';
const resetValues = () => ({
  type: RESET_VALUES
});
setGlobal('svs.components.marketplaceData.createGame.actions', {
  CHANGE_MAX_FRACTIONS_PER_MEMBER,
  changeMaxFractionsPerMember,
  CHANGE_MAX_FRACTIONS_PER_GAME,
  changeMaxFractionsPerGame,
  CHANGE_FRACTION_PRICE,
  changeFractionPrice,
  CHANGE_DESCRIPTION,
  changeDescription,
  CHANGE_BUY_STOP_DIFF,
  changeBuyStopDiff,
  FETCH_GAME,
  FETCH_GAME_FAILED,
  FETCH_GAME_SUCCESS,
  fetchGame,
  SELECT_PRODUCT,
  selectProduct,
  SELECT_DRAW,
  selectDraw,
  SELECTED_DRAW_CLOSED,
  selectedDrawClosed,
  CREATE_GAME,
  CREATE_GAME_SUCCESS,
  CREATE_GAME_FAILED,
  createGame,
  UPDATE_GAME,
  UPDATE_GAME_FAILED,
  UPDATE_GAME_SUCCESS,
  updateGame,
  RESET_VALUES,
  resetValues,
  CLEAR_FORM,
  clearFormAction
});

 })(window);