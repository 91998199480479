(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-game/assets/javascripts/services/update-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-game/assets/javascripts/services/update-game.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  resolveTimeZone
} = svs.components.lbUtils.helpers.formatDate;
const updateGame = (game, callback) => {
  jupiter.put({
    path: "/marketplace/1/activateddraws/".concat(game.activatedDrawId),
    data: {
      maxSharesPerMember: game.maxFractionsPerMember,
      description: game.description,
      buyinCloseTime: game.buyStop && dateFns.formatISO(resolveTimeZone(game.buyStop)) 
    }
  }, () => callback(undefined, game), error => callback(error.responseJSON.error, undefined));
};
setGlobal('svs.components.marketplaceData.createGame.services.updateGame', updateGame);

 })(window);